import "../assets/styles/Footer.css";

export default function RegFooter() {
  const date = new Date().getFullYear();
  return (
    <div className="reg__footer">
      <div className="reg__footer-content">
        <p>Copyright &copy; {date} Rework Academy. All Rights Reserved.</p>
      </div>
    </div>
  );
}
